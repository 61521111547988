import {
  REHAB_FORM_NAMES,
  REHAB_FORM_SUB_TYPES,
  REHAB_FORM_TYPES,
} from "core/consts";
import {
  Auction,
  GetOntologyType,
  RehabFormName,
  RehabFormSubType,
  RehabFormType,
  ValueOf,
} from "core/types";
import { PDFForm } from "pdf-lib";
import { ConversionJobs } from "react-forms-state";
import Translations from "translations/types";
import { DRVGeneralFormPresenter } from "./DRVGeneralForm/FormSections";
import { importAuctionToDRVGeneralForm } from "./DRVGeneralForm/importAuction";
import { DRVgeneralFormDefinition } from "./DRVGeneralForm/modelDefinition";
import { DRVGeneralFormMapping } from "./DRVGeneralForm/pdfMapping";
import { DRVMedicalFormPresenter } from "./DRVMedicalForm/FormSections";
import { importAuctionToDRVMedicalForm } from "./DRVMedicalForm/importAuction";
import { DRVmedicalFormDefinition } from "./DRVMedicalForm/modelDefinition";
import { DRVMedicalFormMapping } from "./DRVMedicalForm/pdfMapping";
import { GKVGeneralFormPresenter } from "./GKVGeneralForm";
import { importAuctionToGKVGeneralForm } from "./GKVGeneralForm/importAuction";
import { GKVgeneralFormDefinition } from "./GKVGeneralForm/modelDefinition";
import { GKVGeneralFormMapping } from "./GKVGeneralForm/pdfMapping";
import { GKVMedicalFormPresenter } from "./GKVMedicalForm";
import { importAuctionToGKVMedicalForm } from "./GKVMedicalForm/importAuction";
import { GKVmedicalFormDefinition } from "./GKVMedicalForm/modelDefinition";
import { GKVmedicalFormMapping } from "./GKVMedicalForm/pdfMapping";
import { RehabFormDataMapped } from "./utils";

export const GKV_GENERAL_FORM_PATH =
  "rehab_general_universal_third_draft.pdf" as const;
export const GKV_MEDICAL_FORM_PATH =
  "rehab_medical_universal_fifth_draft.pdf" as const;
export const DRV_GENERAL_FORM_PATH = "general_pension_form_v3.pdf" as const;
export const DRV_MEDICAL_FORM_PATH =
  "medical_pension_form_first_draft.pdf" as const;

export const GKV_GENERAL_FILE_NAME =
  "antrag_anschlussrehabilitation.pdf" as const;
export const GKV_MEDICAL_FILE_NAME =
  "antrag_aerztlicher_befundbericht.pdf" as const;
export const DRV_GENERAL_FORM_NAME = "drv_antrag_G0250.pdf" as const;
export const DRV_MEDICAL_FORM_NAME = "drv_G0260_G0600.pdf" as const;

const REHAB_FORM_PATHS = [
  GKV_GENERAL_FORM_PATH,
  GKV_MEDICAL_FORM_PATH,
  DRV_GENERAL_FORM_PATH,
  DRV_MEDICAL_FORM_PATH,
] as const;

export type RehabFormPath = (typeof REHAB_FORM_PATHS)[number];

const REHAB_FORM_FILE_NAMES = [
  GKV_GENERAL_FILE_NAME,
  GKV_MEDICAL_FILE_NAME,
  DRV_GENERAL_FORM_NAME,
  DRV_MEDICAL_FORM_NAME,
] as const;

export type RehabFormFileName = (typeof REHAB_FORM_FILE_NAMES)[number];

export type RehabFormFormGetters<T extends RehabFormName> = Partial<
  Record<
    keyof RehabFormDataMapped<T>,
    (auction: Auction) => ValueOf<RehabFormDataMapped<T>> | null
  >
>;

export type PdfFillerProps<T extends RehabFormName> = {
  form: PDFForm;
  formData: RehabFormDataMapped<T>;
  locale: Locale;
};

export type PdfFiller<T extends RehabFormName> = ({
  form,
  formData,
  locale,
}: PdfFillerProps<T>) => void;

type FormConfig = {
  Form: ({
    onChange,
  }: {
    onChange: (value: any, statePath: string, validation?: any) => void;
  }) => JSX.Element;
  getFormDescription: (translations: Translations) => string;
  getFormTitle: (translations: Translations) => string;
  importAuctionToFormGetters: (
    getOntology: GetOntologyType,
    translations: Translations,
  ) => RehabFormFormGetters<any>;
  modelDefinition: ConversionJobs;
};

type PdfConfig = {
  blankPdfAssetPath: RehabFormPath;
  downloadPdfFileName: RehabFormFileName;
  pdfFiller: PdfFiller<any>;
};

type RehabFormConfig = {
  form: FormConfig;
  formSubType: RehabFormSubType;
  formType: RehabFormType;
  pdf: PdfConfig;
};

export const REHAB_FORM_CONFIGS: Record<RehabFormName, RehabFormConfig> = {
  [REHAB_FORM_NAMES.GKV_MEDICAL]: {
    formType: REHAB_FORM_TYPES.MEDICAL,
    formSubType: REHAB_FORM_SUB_TYPES.GKV,
    pdf: {
      blankPdfAssetPath: GKV_MEDICAL_FORM_PATH,
      downloadPdfFileName: GKV_MEDICAL_FILE_NAME,
      pdfFiller: GKVmedicalFormMapping,
    },
    form: {
      Form: GKVMedicalFormPresenter,
      getFormTitle: (translations: Translations) =>
        translations.patientForms.medicalForm.title,
      getFormDescription: (translations: Translations) =>
        translations.patientForms.medicalForm.description,
      modelDefinition: GKVmedicalFormDefinition,
      importAuctionToFormGetters: importAuctionToGKVMedicalForm,
    },
  },
  [REHAB_FORM_NAMES.GKV_GENERAL]: {
    formType: REHAB_FORM_TYPES.GENERAL,
    formSubType: REHAB_FORM_SUB_TYPES.GKV,
    pdf: {
      blankPdfAssetPath: GKV_GENERAL_FORM_PATH,
      downloadPdfFileName: GKV_GENERAL_FILE_NAME,
      pdfFiller: GKVGeneralFormMapping,
    },
    form: {
      Form: GKVGeneralFormPresenter,
      getFormTitle: (translations: Translations) =>
        translations.patientForms.generalForm.title,
      getFormDescription: (translations: Translations) =>
        translations.patientForms.generalForm.description,
      modelDefinition: GKVgeneralFormDefinition,
      importAuctionToFormGetters: importAuctionToGKVGeneralForm,
    },
  },
  [REHAB_FORM_NAMES.DRV_GENERAL]: {
    formType: REHAB_FORM_TYPES.GENERAL,
    formSubType: REHAB_FORM_SUB_TYPES.DRV,
    pdf: {
      blankPdfAssetPath: DRV_GENERAL_FORM_PATH,
      downloadPdfFileName: DRV_GENERAL_FORM_NAME,
      pdfFiller: DRVGeneralFormMapping,
    },
    form: {
      Form: DRVGeneralFormPresenter,
      getFormTitle: (translations: Translations) =>
        translations.patientForms.drvGeneralForm.title,
      getFormDescription: (translations: Translations) =>
        translations.patientForms.drvGeneralForm.description,
      modelDefinition: DRVgeneralFormDefinition,
      importAuctionToFormGetters: importAuctionToDRVGeneralForm,
    },
  },
  [REHAB_FORM_NAMES.DRV_MEDICAL]: {
    formType: REHAB_FORM_TYPES.MEDICAL,
    formSubType: REHAB_FORM_SUB_TYPES.DRV,
    pdf: {
      blankPdfAssetPath: DRV_MEDICAL_FORM_PATH,
      downloadPdfFileName: DRV_MEDICAL_FORM_NAME,
      pdfFiller: DRVMedicalFormMapping,
    },
    form: {
      Form: DRVMedicalFormPresenter,
      getFormTitle: (translations: Translations) =>
        translations.patientForms.drvMedicalForm.title,
      getFormDescription: (translations: Translations) =>
        translations.patientForms.drvMedicalForm.description,
      modelDefinition: DRVmedicalFormDefinition,
      importAuctionToFormGetters: importAuctionToDRVMedicalForm,
    },
  },
};

export const getRehabFormConfig = (formName: RehabFormName): RehabFormConfig =>
  REHAB_FORM_CONFIGS[formName];
